import axios from "axios";
let refreshTokenInterval;

const checkAuth = async () => {
  const TOKEN = localStorage.getItem("access_token");
  const REFRESH_TOKEN = localStorage.getItem("refresh_token");
  const TokenExpiration = localStorage.getItem("access_token_expires_at");
  const BASE_URL = window.location.origin;

  const PUBLIC_ROUTE_PATTERNS = [
    `${BASE_URL}/login`,
    new RegExp(`${BASE_URL}/email-verify/.+`),
    new RegExp(`${BASE_URL}/reset-password/.+`),
  ];

  const isPublicPage = PUBLIC_ROUTE_PATTERNS.some((pattern) =>
    pattern instanceof RegExp
      ? pattern.test(window.location.href)
      : window.location.href === pattern
  );

  // If there's no token and it's not a public page, redirect to login
  if (!TOKEN && !isPublicPage) {
    redirectToLogin();
    return;
  }

  // If the token is valid but the user tries to access the login page, redirect to the dashboard
  if (TOKEN && window.location.href === `${BASE_URL}/login`) {
    redirectToDashboard();
    return;
  }

  if (TokenExpiration) {
    const expiryTimeInterval = calculateExpiryTimeInterval(TokenExpiration);
    if (expiryTimeInterval <= 60000) {
      // If less than or equal to 1 minute remaining
      try {
        await refreshAuthToken(REFRESH_TOKEN);
      } catch (error) {
        handleTokenRefreshError();
      }
    } else {
      setupAxiosInterceptors(TOKEN);
      setupRefreshTokenInterval(expiryTimeInterval);
    }
  }

  return TOKEN;
};

const redirectToLogin = () => {
  window.location.href = "/login";
  clearInterval(refreshTokenInterval);
};

const redirectToDashboard = () => {
  window.location.href = "/dashboard"; // Redirect to your dashboard page
  clearInterval(refreshTokenInterval);
};

const calculateExpiryTimeInterval = (TokenExpiration) => {
  const expiryDate = new Date(JSON.parse(TokenExpiration));
  const currentDate = new Date();
  return expiryDate - currentDate; // Time remaining until expiration
};

const setupAxiosInterceptors = (TOKEN) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.data === "Token is expired") {
        redirectToLogin();
      }
      return Promise.reject(error);
    }
  );
};

const setupRefreshTokenInterval = (expiryTimeInterval) => {
  if (refreshTokenInterval) clearInterval(refreshTokenInterval);

  refreshTokenInterval = setInterval(async () => {
    try {
      await refreshAuthToken(localStorage.getItem("refresh_token"));
    } catch (error) {
      console.error("Error refreshing token:", error);
      handleTokenRefreshError();
    }
  }, expiryTimeInterval - 60000); // Set interval 1 minute before expiration
};

const refreshAuthToken = (REFRESH_TOKEN) => {
  return axios
    .post("https://justfashionapp.com/v1/brand-admin-auth/refresh-tokens", {
      refreshToken: REFRESH_TOKEN,
    })
    .then((response) => {
      updateTokens(response);
    });
};

const updateTokens = (response) => {
  const newToken = response?.data?.access?.token;
  localStorage.setItem("access_token", newToken);

  const newTokenExpiration = response.data?.access?.expires;
  localStorage.setItem(
    "access_token_expires_at",
    JSON.stringify(newTokenExpiration)
  );

  const newRefreshToken = response.data?.refresh?.token;
  localStorage.setItem("refresh_token", newRefreshToken);
};

const handleTokenRefreshError = () => {
  const theme = localStorage.getItem("theme");
  const language = localStorage.getItem("language");
  localStorage.clear();
  localStorage.setItem("theme", theme);
  localStorage.setItem("language", language);
  redirectToLogin();
};

export default checkAuth;
